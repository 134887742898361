/* .ant-result-title {
    color: white !important;

}

.ant-result-subtitle {
    color: white !important;

} */

#page404 {
    background-color: white;
}