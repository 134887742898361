.ant-menu-item-active {
    background-color: transparent !important;
}

.ant-menu-item-selected {
    background-color: transparent !important;
}

.margin-left {
    background-color: #286090;
    color: #fff;

}

.c-w-i input {
    color: #fff !important;
    font-size: 1.225rem;
    line-height: 2.25rem;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff;
}