.striped-row {
    background-color: #0D0F27
  }

  .striped-row-2 {
    background-color: #14183D
  }


.ant-table{
  background: unset !important;
  color: #ffffff !important;
}
.ant-table-cell{
  /* background: #faebd700 !important; */
  color: #ffffff !important;
}

.ant-table-tbody tr{
  cursor: pointer;
  border: none !important;
}
.ant-table-tbody tr:hover{
  background: #1E245C !important;
}
.ant-table-tbody td{
  border: none !important;
}

.ant-table-tbody tr:hover{
  background: rgba(30, 36, 92, 0.315) !important;
}
/* .ant-table-tbody:first-child{
  background: #5c5c6f61!important;
}
.ant-table-tbody:nth-child(2){
  background: #e0d9c054 !important;
}
.ant-table-tbody:nth-child(3){
  background: #5c5c6f61 !important;
} */
.ant-table-thead{
  background: #1c202078 !important;
}

.ant-table-cell{
  background: unset !important;
}

/* .atbody:nth-child(even){
  background: #5c5c6f61 !important; 
} */

/* table tbody .tbody:nth-child(even) {background: #312f2f}
.tbody:nth-child(odd) {background: #FFF} */

.tr-even{
  background: #5c5c6f61!important;
}
.tr-odd{
  background: #e0d9c054 !important;
}