body {
  background: linear-gradient(to right, var(--tw-gradient-stops));

}


/* .bg-custom{

} */

.bg-custom {

  background: linear-gradient(342deg, #8350AA 26%, hsla(280.43, 82.14%, 67.06%, 0.2564117865409443) 72%), radial-gradient(circle at -92% 42%, hsla(113.33, 36%, 4.9%, 0.10625098414865164) 33%, #3800ff 63%), repeating-linear-gradient(210deg, #57e0ec 49%, #f9c7d1 71%), linear-gradient(210deg, #1CD8FA 61%, hsla(120, 90.91%, 95.69%, 0.725296942643785) 100%), linear-gradient(173deg, #3800ff 12%, hsla(240, 6.67%, 20.59%, 0.19878590100563254) 80%);
  /* background: linear-gradient(300deg,deepskyblue,darkviolet,blue); */
  background-size: 180% 180%;
  /* animation: gradient-animation 5s ease infinite; */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.ant-card-body {
  border-radius: 8px !important;
}

.gw-mt-2 {
  margin-top: 0.5rem;
  /* display: none !important; */
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #3900ff !important;
  border-color: #ffffff !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}

#getWaitlistContainer input {
  font-size: 1.2rem !important;
}

#getWaitlistContainer button,
#getWaitlistContainer input {
  height: 40px;
  margin: 5px;
  border-radius: 20px !important;
}

#getWaitlistContainer button {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

/*  /* margin-top: 4% !important; */

.ju367vn {

  border-radius: 10px !important;
  min-width: 12rem !important;
  text-align: center !important;
  background: #3800ff !important;
  border: 1px solid #fff !important;
  height: 3rem !important;
  margin: 0.5rem !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: #ffffff !important;

}

.ju367v70 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

}



.ju367vn:hover {
  color: #4096ff !important;
  border-color: #4096ff !important;
  background-color: #191a1c98 !important;
}